<template>
  <div>
    <document v-for="doc of docs" :key="doc.id" :doc="doc" @reloadDocs="$emit('reloadDocs')" type="investor"></document>
  </div>
</template>

<script>
import Document from '@/components/Document/Document'
export default {
  name: 'investor-docs',
  props: ['docs'],
  components: { Document }
}
</script>

<style lang="sass" scoped>

</style>

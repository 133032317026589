<template>
  <div class="w-full">
    <div class="row">
      <v-input class="w-full lg:w-4/12 lg:pr-6" name="facebook" v-model="profile.facebook" :error="errors.first('facebook')" label="Facebook" prefix="facebook.com/" v-validate="'min:2'"></v-input>
      <v-input class="w-full lg:w-4/12 lg:pr-6" name="instagram" v-model="profile.instagram" :error="errors.first('instagram')" label="Instagram" prefix="instagram.com/" v-validate="'min:2'"></v-input>
      <v-input class="w-full lg:w-4/12 " name="linkedin" v-model="profile.linkedin" :error="errors.first('linkedin')" label="Linkedin" prefix="linkedin.com/" v-validate="'min:2'"></v-input>
    </div>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'
export default {
  name: 'social',
  components: { VInput },
  props: ['profile'],
  inject: ['$validator']
}
</script>

<style lang="sass" scoped>
.row
  @apply block flex flex-col
  @screen lg
    @apply flex flex-row flex-wrap
</style>

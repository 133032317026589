<template>
  <div class="w-full"  style="display: none">
    <div class="row mt-2 lg:mb-1">
      <div class="w-full lg:w-1/2 lg:pr-6">
        <v-checkbox class="text-sm" name="autorizacaoContato.email" v-model="profile.autorizacaoContato.email" label="Aceito receber informações e contatos por E-mail" v-validate="''"/>
      </div>
      <div class="w-full lg:w-1/2">
        <v-checkbox class="text-sm" name="autorizacaoContato.sms" v-model="profile.autorizacaoContato.sms" label="Aceito receber informações e contatos por SMS" v-validate="''"/>
      </div>
    </div>
    <div class="row">
      <div class="w-full lg:w-1/2 lg:pr-6">
        <v-checkbox class="text-sm" name="autorizacaoContato.telefone" v-model="profile.autorizacaoContato.telefone" label="Aceito receber informações e contatos por Telefone" v-validate="''"/>
      </div>
      <div class="w-full lg:w-1/2">
        <v-checkbox class="text-sm" name="autorizacaoContato.whatsApp" v-model="profile.autorizacaoContato.whatsApp" label="Aceito receber informações e contatos por WhatsApp" v-validate="''"/>
      </div>
    </div>
  </div>
</template>

<script>
import VCheckbox from '@/components/Inputs/Checkbox'
export default {
  components: { VCheckbox },
  props: ['profile'],
  inject: ['$validator']
}
</script>

<style lang="sass" scoped>

.row
  @apply block flex flex-col
  @screen lg
    @apply flex flex-row flex-wrap

.label
  @apply text-sm opacity-75 cursor-pointer mr-2 select-none

.radio
  @apply mr-1 my-auto

</style>

<template>
  <div>
    <div class="avatar-upload" v-show="!edit">
      <div class="avatar_box">
        <label for="avatar" class="avatar">
          <div class="overlay">Mudar foto</div>
          <img :src="files.length ? files[0].url : $store.state.investor.avatar" class="rounded-circle" />
        </label>
      </div>
      <file-upload
        extensions="png,jpg,jpge,jpeg,bmp"
        accept="image/png,image/jpeg,image/bmp"
        :size="10 * 1024 * 1024"
        name="avatar"
        class="btn btn-primary"
        post-action="/upload/post"
        :drop="!edit"
        v-model="files"
        @input-filter="inputFilter"
        @input-file="inputFile"
        ref="upload">
      </file-upload>
    </div>

    <div class="modal" v-show="files.length && edit">
      <div class="backdrop" @click="$emit('closeModal')"/>
      <div class="container flex justify-center items-center">
        <div class="content">
          <div class="avatar-edit-image" v-if="files.length">
            <img ref="editImage" :src="files[0].url" />
          </div>
          <div class="w-full flex justify-center">
            <div class="pr-1 lg:pr-2">
              <v-button color="error" size="sm" @click.native.prevent="$refs.upload.clear" :loading="loading">Cancelar</v-button>
            </div>
            <div class="pl-1 lg:pl-2">
              <v-button size="sm" @click="editSave" :loading="loading">Mudar</v-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
import 'cropperjs/dist/cropper.css'

import VButton from '@/components/Buttons/Button'
import Profile from '@/services/Investor/Profile/Profile'

export default {
  components: {
    FileUpload,
    VButton
  },

  data () {
    return {
      files: [],
      edit: false,
      cropper: false,
      loading: false
    }
  },

  watch: {
    edit (value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }
          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 1 / 1,
            viewMode: 1
          })
          this.cropper = cropper
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }
      }
    }
  },

  async mounted () {
      const avatar = await (await Dashboard.getAvatar().data);
      this.$store.dispatch('setAvatar', avatar ? this.getBase64(avatar) : null)
  },

  methods: {
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async editSave () {
      let oldFile = this.files[0]
      let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }

      let file = new File([arr], oldFile.name, { type: oldFile.type })
      try {
        this.loading = true
        const data = new FormData()
        data.append('Arquivo', file)
        await Profile.putAvatar(data)

     const avatar = await (await Dashboard.getAvatar().data);
      this.$store.dispatch('setAvatar', avatar ? this.getBase64(avatar) : null)

        this.$refs.upload.update(oldFile.id, {
          file,
          type: file.type,
          size: file.size
        })
      } catch (error) {
        console.error(error)
        this.$store.dispatch('notification', { type: 'error', message: 'Houve algum erro ao enviar o avatar. Tente novamente.' })
      } finally {
        this.edit = false
        this.loading = false
      }
    },

    inputFile (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },

    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(png|jpg|jpge|jpeg|bmp)$/i.test(newFile.name)) {
          this.$store.dispatch('notification', { type: 'error', message: 'Formato inválido.' })
          return prevent()
        }
        if (newFile.size > 10 * 1024 * 1024) {
          this.$store.dispatch('notification', { type: 'error', message: 'Arquivo muito grande.' })
          return prevent()
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.rounded-circle
  @apply rounded-full w-full h-full object-cover

.avatar_box
  @apply relative w-32 h-32 rounded-full cursor-pointer
  @screen xl
    @apply w-40 h-40
  &-upload
    @apply rounded-full cursor-pointer w-32 h-32
    @screen xl
      @apply w-40 h-40

.avatar
  @apply cursor-pointer
  &:hover > .overlay
    @apply flex

.overlay
  @apply hidden absolute w-32 h-32 rounded-full items-center justify-center text-white font-medium
  &:hover
    @apply flex
  background-color: rgba(25,25,25,0.3)
  @screen xl
    @apply w-40 h-40

.modal
  @apply fixed flex items-center justify-center z-50 w-screen h-screen top-0 left-0
  max-height: 768

.backdrop
  @apply fixed z-50 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full h-full bg-white z-50 flex flex-col justify-center pb-6 pt-8 rounded-lg

.avatar-edit-image
  max-height: 80vh

</style>

<style lang="sass">

.cropper-crop-box, .cropper-view-box
  @apply rounded-full

.cropper-container
  max-height: 768px

</style>

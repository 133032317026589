<template>
  <div class="content">
    <p class="text">Você já preencheu todos os dados pessoais. Revise-os e os mantenha atualizados.</p>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>

.content
  @apply w-full bg-transparent border-2 border-blue-600 text-blue-600 py-2 text-center bg-blue-100 rounded-lg

.text
  @apply text-xs
  @screen lg
    @apply text-sm

</style>

<template>
  <div class="w-full">
      <div class="row">
        <v-input class="w-full lg:w-6/12 lg:pr-6" name="nomeCompleto" v-capitalizeName v-trim v-model="profile.nomeCompleto" :error="errors.first('nomeCompleto')" label="Nome Completo*" v-validate="'required|fullName'"></v-input>
        <v-input class="w-full lg:w-3/12" mask="(##) #####-####" :masked="true" name="telefoneContatoDireto" v-model="profile.telefoneContatoDireto" :error="errors.first('telefoneContatoDireto')" label="Telefone*" v-validate="'required|min:14|max:15'"></v-input>
      </div>
      <div class="row">
        <v-input class="w-full lg:w-3/12 lg:pr-6" name="cpf" v-model="profile.cpf" :error="errors.first('cpf')" label="CPF*" mask="###.###.###-##" :disabled="disableCPF" v-validate="'required|cpf'"></v-input>
        <v-input class="w-full lg:w-3/12 lg:pr-6" name="rg" v-model="profile.rg" :error="errors.first('rg')" label="RG*" v-validate="'required'"></v-input>
        <v-input class="w-full lg:w-3/12 lg:pr-6" name="orgaoExpeditor" v-model="profile.orgaoExpeditor" :error="errors.first('orgaoExpeditor')" label="Órgão de Expedição RG*" v-validate="'required|min:2'"></v-input>
        <v-input class="w-full lg:w-3/12" name="dataExpedicaoRG" v-model="profile.dataExpedicaoRG" :error="errors.first('dataExpedicaoRG')" mask="##/##/####" :masked="true" label="Data de Expedição RG*" v-validate="'required|date_format:dd/MM/yyyy'"></v-input>
      </div>
      <div class="row">
        <v-input class="w-full lg:w-3/12 lg:pr-6" name="nacionalidade" v-model="profile.nacionalidade" :error="errors.first('nacionalidade')" label="Nacionalidade*" :options="computedNacionalidades" v-validate="{required: true, is_not: -1}" itemText="descricao" itemValue="descricao"></v-input>
        <v-input v-if="isBR" class="w-full lg:w-3/12 lg:pr-6" name="ufNascimento" v-model="profile.ufNascimento" :error="errors.first('ufNascimento')" label="UF de Nascimento*" :options="computedUfs" v-validate="{required: true, is_not: -1}" itemText="descricao" itemValue="descricao"></v-input>
        <v-input v-else class="w-full lg:w-3/12 lg:pr-6" name="ufNascimento" v-model="profile.ufNascimento" :error="errors.first('ufNascimento')" label="UF de Nascimento*" v-validate="'required|length:2'"></v-input>
        <v-input v-if="isBR" class="w-full lg:w-3/12 lg:pr-6" name="cidadeNascimento" v-model="profile.cidadeNascimento" :error="errors.first('cidadeNascimento')" label="Cidade de Nascimento*" :options="computedCities" v-validate="{required: true, is_not: -1}" itemText="descricao" itemValue="descricao" autocomplete="off"></v-input>
        <v-input v-else class="w-full lg:w-3/12 lg:pr-6" name="cidadeNascimento" v-model="profile.cidadeNascimento" :error="errors.first('cidadeNascimento')" label="Cidade de Nascimento*" v-validate="'required|min:2'"></v-input>
        <v-input class="w-full lg:w-3/12" name="dataNascimento" v-model="profile.dataNascimento" :error="errors.first('dataNascimento')" mask="##/##/####" :masked="true" label="Data de Nascimento*" v-validate="'required|date_format:dd/MM/yyyy'"></v-input>
      </div>
      <div class="row">
        <v-input class="w-full lg:w-3/12 lg:pr-6" name="estadoCivil" v-model="profile.estadoCivil" :error="errors.first('estadoCivil')" label="Estado Civil*" :options="enums.estadosCivis" v-validate="{required: true, is_not: -1}" itemText="descricao" itemValue="id"></v-input>
        <v-input class="w-full lg:w-3/12 lg:pr-6" name="sexo" v-model="profile.sexo" :error="errors.first('sexo')" label="Sexo*" :options="enums.sexos" v-validate="{required: true, is_not: -1}" itemText="descricao" itemValue="id"></v-input>
        <v-input class="w-full lg:w-3/12 lg:pr-6" type="money" name="rendaMensalAproximada" v-model="profile.rendaMensalAproximada" :error="errors.first('rendaMensalAproximada')" label="Renda Mensal Aproximada*" v-validate="'decimal:2|min_value:1'"></v-input>
        <v-input class="w-full lg:w-3/12" type="money" name="patrimonioAproximado" v-model="profile.patrimonioAproximado" :error="errors.first('patrimonioAproximado')" label="Patrimônio*" v-validate="'decimal:2|min_value:1'"></v-input>
      </div>
      <div class="row">
        <v-input class="w-full lg:w-1/2 lg:pr-6" name="nomeMae" v-model="profile.nomeMae" :error="errors.first('nomeMae')" label="Nome da mãe*" v-validate="'required|min:2'"></v-input>
        <v-input class="w-full lg:w-1/2" name="nomePai" v-model="profile.nomePai" :error="errors.first('nomePai')" label="Nome do pai" ></v-input>
      </div>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'
import Cep from '@/services/Commons/Cep'

export default {
  name: 'personal-data',
  components: { VInput },
  props: ['profile', 'enums', 'disableCPF'],
  inject: ['$validator'],
  data () {
    return {
      firstLoad: true,
      isBR: false,
      ufs: [],
      cities: []
    }
  },
  computed: {
    computedNacionalidade () {
      return this.profile.nacionalidade
    },
    computedUF () {
      return this.profile.ufNascimento
    },
    computedNacionalidades () {
      return this.enums.nacionalidades.map(n => ({ descricao: n }))
    },
    computedUfs () {
      return this.ufs.map(uf => ({ descricao: uf }))
    },
    computedCities () {
      return this.cities.map(city => ({ descricao: city }))
    }
  },
  methods: {
    async getUfs () {
      try {
        this.ufs = (await Cep.getUfs()).data.data
      } catch (error) {
        console.error(error)
      }
    },
    async getCities () {
      try {
        this.cities = (await Cep.getCities(this.profile.ufNascimento)).data.data
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    computedNacionalidade () {
      this.isBR = this.profile.nacionalidade === 'Brasileiro'
      if (this.isBR) {
        if (this.firstLoad) {
          this.getUfs()
          if (this.profile.ufNascimento !== '') this.getCities()
          this.firstLoad = false
        } else {
          this.profile.ufNascimento = -1
          this.profile.cidadeNascimento = -1
        }
      } else {
        if (!this.firstLoad) {
          this.profile.ufNascimento = ''
          this.profile.cidadeNascimento = ''
        }
      }
    },
    computedUF () {
      if (this.isBR && this.profile.ufNascimento !== '' && this.profile.ufNascimento !== -1) {
        this.getCities()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.row
  @apply block flex flex-col
  @screen lg
    @apply flex flex-row flex-wrap

</style>

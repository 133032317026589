<template>
  <div class="w-full">
    <div class="row">
      <p class="warning"><strong>ATENÇÃO:</strong> Informe uma conta bancária válida devidamente registrada em seu nome e CPF informados em seu cadastro. Você só poderá realizar novos investimento após ter sua conta verificada e aprovada, o processo de verificação pode levar até 1 dia útil.</p>
      <v-input :options="enums.banks" itemValue='instituicaoFerbranId' :itemText="['codigo', 'nome']" class="w-full lg:w-6/12 lg:pr-6" name="bancoId" v-model="profile.bancoId" :error="errors.first('instituicaoFerbranId')" label="Conta Bancária*" v-validate="{required: true, is_not: -1}"></v-input>
      <v-input :options="enums.accounts" itemValue='id' :itemText="'descricao'" class="w-full lg:w-6/12" name="tipoConta" v-model="profile.tipoConta" :error="errors.first('tipoConta')" label="Tipo Conta*" v-validate="{required: true, is_not: -1}"></v-input>
    </div>
    <div class="row">
      <v-input class="w-full lg:w-3/12 lg:pr-6 flex-grow" name="agencia" v-model="profile.agencia" :error="errors.first('agencia')" label="Agência*" v-validate="'required'"></v-input>
      <v-input v-show="shouldAgencyDigitAppear" class="w-full lg:w-3/12 lg:pr-6 flex-grow" name="digitoAgencia" v-model="profile.digitoAgencia" :error="errors.first('digitoAgencia')" label="Digito da agência*"  v-validate="{ length: 1, required: shouldAgencyDigitAppear}"></v-input>
      <v-input class="w-full lg:w-3/12 lg:pr-6 flex-grow" name="conta" v-model="profile.conta" :error="errors.first('conta')" label="Conta*" v-validate="'required'"></v-input>
      <v-input v-show="shouldAccountDigitAppear" class="w-full lg:w-3/12 lg:pr-6 flex-grow" name="digitoConta" v-model="profile.digitoConta" :error="errors.first('digitoConta')" label="Digito da conta*"  v-validate="{ length: 1, required: shouldAccountDigitAppear}"></v-input>
    </div>
    <div class="row" v-if="status.aprovadoNaIugu">
      <div class="infor">
        <p class="success"><strong>Status atual:</strong> Sua conta já foi verificada e está aprovada! :)</p>
        <p>
          <strong>Seus dados bancários válidos até o momento:</strong>
        </p>
        <p>
          <strong>Banco:</strong> {{approvedBankAccount.banco}}
        </p>
        <p>
          <strong>Agência:</strong> {{approvedBankAccount.agencia}}
        </p>
        <p>
          <strong>Conta:</strong> {{approvedBankAccount.conta}}
        </p>
        <p>
          <strong>Tipo Conta:</strong> {{approvedBankAccount.tipoConta}}
        </p>
      </div>
    </div>
    <div class="row" v-else-if="status.enviadoParaVerificacaoIugu">
      <div class="infor">
        <p class="text-center"><strong>Status atual:</strong> Sua conta bancária foi enviada para aprovação!</p>
      </div>
    </div>
    <div class="row" v-else>
      <div class="infor">
        <p class="text-center"><strong>Status atual:</strong> Você ainda não cadastrou sua conta bancária! </p>
      </div>
    </div>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'

export default {
  name: 'bank-data',
  components: { VInput },
  props: ['profile', 'enums', 'approvedBankAccount', 'status'],
  inject: ['$validator'],
  computed: {
    currentBankCode () {
      const currentBank = this.enums.banks.find(bank => bank.instituicaoFerbranId === this.profile.bancoId)
      return currentBank ? +currentBank.codigo : -1
    },
    shouldAccountDigitAppear () {
      const banksWithAccountDigit = [341, 104, 85, 1, 33, 655, 237, 41, 260, 756, 290, 77, 212, 70,336,85,655,260,290,212,422,746,21,136,274,212,389,364,336,218,82,99,197]
      return banksWithAccountDigit.includes(this.currentBankCode)
    },
    shouldAgencyDigitAppear () {
      const banksWithAgencyDigit = [1, 237]
      return banksWithAgencyDigit.includes(this.currentBankCode)
    }
  },
  watch: {
    shouldAccountDigitAppear (val) {
      if (!val) this.profile.digitoConta = ''
    },
    shouldAgencyDigitAppear (val) {
      if (!val) this.profile.digitoAgencia = ''
    }
  }
}
</script>

<style lang="sass" scoped>
.row
  @apply block flex flex-col
  @screen lg
    @apply flex flex-row flex-wrap

.warning
  @apply text-yellow-600 text-sm border border-yellow-500 shadow-md rounded-lg p-2 mt-4
  @screen lg
    @apply mt-1 mb-4

.success
  @apply text-primary text-sm border border-primary shadow-md rounded-lg p-2 my-4 text-center
  @screen lg
    @apply mt-2

.infor
  @apply w-full text-blue-500 text-sm border border-blue-500 shadow-md rounded-lg p-2 mt-4
  @screen lg
    @apply mt-2
</style>

<template>
  <div class="relative flex flex-wrap mb-16">
    <change-password-modal v-if="modalPassword" @closeModal="modalPassword = false" req="investidor"/>
    <div class="row my-4">
      <p class="title">Meu cadastro</p>
      <profile-filled-alert v-show="status.cadastroCompleto"/>
    </div>
    <div class="row">
      <div class="backdrop" v-show="loadingPage"><div class="fas fa-dollar-sign spin"></div></div>
      <profile-forms class="w-full lg:w-9/12"
        :profile="profile"
        :enums="enums"
        :disableCPF="disableCPF"
        :docs="docs"
        :status="status"
        :approvedBankAccount="approvedBankAccount"
        @reloadDocs="loadDocs"
      />
      <div class="avatar lg:w-3/12">
        <div class="flex flex-col items-center">
          <avatar/>
          <p class="name">{{status.nome}}</p>
          <p class="nickname">{{status.apelido}}</p>
        </div>
        <div class="flex flex-col items-center justify-center">
          <v-button class="flex justify-center mt-2 md:mt-0" :disabled="!profileChanged" :loading="loading" @click="submit">Salvar alterações</v-button>
          <v-text-button class="text-sm text-secondary" @click="modalPassword = true">Alterar senha</v-text-button>
          <!-- <v-text-button class="text-sm text-secondary">Alterar e-mail</v-text-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileForms from '@/components/Investor/Profile/Forms/ProfileForms'
import Avatar from '@/components/Investor/Profile/Avatar'
import ProfileFilledAlert from '@/components/Investor/Alerts/ProfileFilledAlert'
import ChangePasswordModal from '@/components/Modal/ChangePasswordModal'
import VButton from '@/components/Buttons/Button'
import VTextButton from '@/components/Buttons/TextButton'
import capitalizeName from '@/mixins/capitalizeName'

import Profile from '@/services/Investor/Profile/Profile'

export default {
  name: 'profile',
  components: { ProfileForms, Avatar, VButton, VTextButton, ProfileFilledAlert, ChangePasswordModal },
  mixins: [capitalizeName],
  data () {
    return {
      loading: false,
      loadingPage: true,
      modalPassword: false,

      disableCPF: false,
      docs: [],
      approvedBankAccount: null,

      enums: {
        sexos: [],
        estadosCivis: [],
        banks: [],
        accounts: [],
        nacionalidades: []
      },

      status: {
        aprovadoNaIugu: false,
        cadastroCompleto: false,
        nome: '',
        apelido: ''
      },

      profile: {
        nomeCompleto: '',
        apelido: '',
        telefoneContatoDireto: '',
        cpf: '',
        rg: '',
        orgaoExpeditor: '',
        dataExpedicaoRG: '',
        cidadeNascimento: '',
        dataNascimento: '',
        estadoCivil: -1,
        sexo: -1,
        nacionalidade: -1,
        rendaMensalAproximada: 0,
        patrimonioAproximado: 0,
        nomePai: '',
        nomeMae: '',
        ufNascimento: '',

        endereco: {
          cep: '',
          logradouro: '',
          numero: '',
          bairro: '',
          complemento: '',
          cidade: -1,
          uf: -1
        },

        facebook: '',
        instagram: '',
        linkedin: '',

        valorDispostoInvestir6meses: 0,
        bancoId: -1,
        agencia: '',
        conta: '',
        digitoConta: '',
        digitoAgencia: '',
        tipoConta: -1,

        autorizacaoContato: {
          email: true,
          sms: true,
          telefone: true,
          whatsApp: true
        }
      }
    }
  },
  computed: {
    profileChanged () {
      return Object.values(this.formFields).map(v => v.changed).some(v => v)
    }
  },
  methods: {
    provide () {
      return {
        $validator: this.$validator
      }
    },
    async loadData () {
      try {
        const response = (await Profile.getProfile()).data.data
        if (response) {
          this.status = response.status
          this.disableCPF = !!response.cpf
          delete response.status
          response.dataExpedicaoRG = this.$moment(response.dataExpedicaoRG).format('DD/MM/YYYY')
          response.dataNascimento = this.$moment(response.dataNascimento).format('DD/MM/YYYY')
          Object.keys(response).forEach(key => {
            if (response[key] !== null && response[key] !== '') this.profile[key] = response[key]
          })
          this.$validator.reset()
        }
      } catch (error) {
        console.error(error)
      }
    },
    async loadDocs () {
      try {
        this.docs = (await Profile.getDocs()).data.data.status
      } catch (error) {
        console.error(error)
      }
    },
    async submit () {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            await Profile.updateProfile({
              ...this.profile,
              nomeCompleto: this.capitalizeName(this.profile.nomeCompleto),
              status: undefined
            })
            this.$store.dispatch('notification', { type: 'success', message: 'Perfil atualizado com sucesso!' })
            this.loadData()
          } catch (error) {
            this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro. Revise o formulário.' })
            if (error.response && error.response.data && error.response.data.erros) {
              Object.entries(error.response.data.erros).forEach(([campo, erro]) => this.errors.add({ field: campo, msg: erro }))
            }
          } finally {
            this.loading = false
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    }
  },
  async mounted () {
    await this.loadData()
    await this.loadDocs()
    try {
      const enums = (await Profile.getEnums()).data.data
      const banks = (await Profile.getBanks()).data.data
      this.approvedBankAccount = (await Profile.getApprovedBankAccount()).data.data
      this.enums = {
        sexos: enums.sexo,
        estadosCivis: enums.estadoCivil,
        accounts: enums.tipoConta,
        banks,
        nacionalidades: enums.nacionalidades
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.loadingPage = false
    }
  }
}
</script>

<style lang="sass" scoped>

.row
  @apply w-full flex flex-col
  @screen lg
    @apply flex-row

.title
  @apply font-bold text-xl text-secondary my-2 w-full
  @screen lg
    @apply text-2xl my-auto

.avatar
  @apply rounded-lg bg-white border shadow flex flex-col items-center py-4 mb-4
  @screen lg
    @apply h-152 ml-2 justify-between sticky mb-0
    top: 1rem

.name
  @apply uppercase text-sm font-bold mt-1
  @screen md
    @apply text-base

.nickname
  @apply text-xs
  @screen md
    @apply text-sm

.backdrop
  @apply absolute w-full h-full bg-white text-5xl text-primary shadow z-20 flex items-center justify-center rounded-lg
  opacity: 0.9

.spin
  animation: rotatey 2s linear infinite

@keyframes rotatey
  from
    transform: rotateY(0deg)
  to
    transform: rotateY(360deg)

</style>

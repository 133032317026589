<template>
  <div class="w-full">
    <div class="row">
      <v-input @blur="getCep" class="w-full lg:w-2/12 lg:pr-6" mask="#####-###" :masked="true" name="endereco_CEP" v-model="profile.endereco.cep" :error="errors.first('endereco_CEP')" label="CEP*" v-validate="'required|length:9'"></v-input>
      <v-input class="w-full lg:w-5/12 lg:pr-6" name="endereco_Logradouro" v-model="profile.endereco.logradouro" :error="errors.first('endereco_Logradouro')" label="Logradouro*" v-validate="'required|min:5'"></v-input>
      <v-input class="w-full lg:w-3/12 lg:pr-6" name="endereco_Numero" v-model="profile.endereco.numero" :error="errors.first('endereco_Numero')" label="Número*" v-validate="'required|min:1'"></v-input>
      <v-input class="w-full lg:w-2/12" name="endereco_Bairro" v-model="profile.endereco.bairro" :error="errors.first('endereco_Bairro')" label="Bairro*" v-validate="'required|min:2'"></v-input>
    </div>
    <div class="row">
      <v-input class="w-full lg:w-6/12 lg:pr-6" name="endereco_Complemento" v-model="profile.endereco.complemento" :error="errors.first('endereco_Complemento')" label="Complemento" v-validate="'min:2'"></v-input>
      <v-input class="w-full lg:w-2/12 lg:pr-6" :options="ufs" itemText="name" itemValue="name" name="endereco_UF" v-model="profile.endereco.uf" :error="errors.first('endereco_UF')" label="UF" v-validate="{required: true, is_not: -1}"></v-input>
      <v-input class="w-full lg:w-4/12" :options="cities" itemText="name" itemValue="name" name="endereco_Cidade" v-model="profile.endereco.cidade" :error="errors.first('endereco_Cidade')" label="Cidade" v-validate="{required: true, is_not: -1}" :disabled="!cities.length"></v-input>
    </div>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'

import Cep from '@/services/Commons/Cep'

export default {
  name: 'adress',
  components: { VInput },
  props: ['profile'],
  inject: ['$validator'],
  data () {
    return {
      ufs: [],
      cities: []
    }
  },
  async mounted () {
    try {
      const ufs = (await Cep.getUfs()).data.data
      this.ufs = ufs.map(uf => ({ name: uf }))
    } catch (err) {
      console.error(err)
    }
  },
  computed: {
    uf () {
      return this.profile.endereco.uf
    }
  },
  methods: {
    async getCep () {
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.profile.endereco.cep)) {
        try {
          const { cidade, uf, complemento, logradouro, bairro } = (await Cep.getCep(this.profile.endereco.cep)).data.data
          await this.getCities(uf)
          this.profile.endereco = { ...this.profile.endereco, cidade, uf, complemento, logradouro, bairro }
        } catch (error) {
          console.error(error)
        }
      }
    },
    async getCities (uf) {
      try {
        const cities = (await Cep.getCities(uf || this.profile.endereco.uf)).data.data
        this.cities = cities.map(city => ({ name: city }))
      } catch (err) {
        console.error(err)
      }
    }
  },
  watch: {
    uf () {
      this.getCities()
    }
  }
}
</script>

<style lang="sass" scoped>
.row
  @apply block flex flex-col
  @screen lg
    @apply flex flex-row flex-wrap
</style>

<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <div v-if="!submitted" class="content_input">
          <v-input name="senhaAntiga" type="password" v-model="password.senhaAtual" :error="errors.first('senhaAntiga')" label="Senha atual*" v-validate="'required|min:6'"></v-input>
          <v-input name="senha" type="password" v-model="password.senha" :error="errors.first('senha')" label="Nova senha*" v-validate="'required|min:6'"></v-input>
          <v-input name="confirmacaoSenha" type="password" v-model="password.confirmacaoSenha" :error="errors.first('confirmacaoSenha')" label="Confirme a nova senha*" v-validate="{required: true, min: 6, is: password.senha}"></v-input>
          <v-button class="flex justify-center" @click="submit" :loading="loading">Alterar</v-button>
        </div>
        <div v-else class="content_confirm">
          <p :class="`title`">Senha alterada com sucesso!</p>
          <v-button class="flex justify-center" @click="close" :loading="loading">Fechar</v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'
import VButton from '@/components/Buttons/Button'

import ChangePassword from '@/services/Commons/ChangePassword'

export default {
  name: 'change-password-modal',
  components: { VInput, VButton },
  props: ['req'],
  data () {
    return {
      submitted: false,
      loading: false,
      password: {
        senhaAtual: '',
        senha: '',
        confirmacaoSenha: ''
      }
    }
  },
  methods: {
    close () {
      this.$emit('closeModal')
    },
    async submit () {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            await ChangePassword.changePassword(this.req, this.password)
            this.submitted = true
          } catch (error) {
            if (error.response && error.response.data && error.response.data.erros) {
              Object.entries(error.response.data.erros).forEach(([campo, erro]) => this.errors.add({ field: campo, msg: erro }))
            }
          } finally {
            this.loading = false
          }
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center z-50 w-screen h-screen top-0 left-0

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full bg-white z-50 flex flex-col justify-center rounded-lg pt-8
  @screen lg
    @apply w-2/3

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.content_input
  @apply flex flex-col w-full px-4
  @screen lg
    @apply px-8

.content_confirm
  @apply flex flex-col items-center px-2

.title
  @apply font-bold mb-3 text-primary

</style>

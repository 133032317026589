<template>
  <div class="w-full bg-white border shadow rounded-lg p-4 pt-1 mb-4 lg:mb-0">
    <table>
      <tr>
        <td @click="showPersonalData = !showPersonalData">
          <span><i :class="`fas fa-${showPersonalData ? 'minus' : 'plus'}`"></i></span>
          Dados Pessoais
        </td>
        <transition name="slide">
          <div v-show="showPersonalData">
            <div class="flex my-2 items-center justify-between">
              <personal-data :profile="profile" :enums="enums" :disableCPF="disableCPF" :isBR="isBR"/>
            </div>
          </div>
        </transition>
      </tr>
      <tr>
        <td @click="showAdress = !showAdress">
          <span><i :class="`fas fa-${showAdress ? 'minus' : 'plus'}`"></i></span>
          Seu Endereço
        </td>
        <transition name="slide">
          <div v-show="showAdress">
            <div class="flex my-2 items-center justify-between">
              <adress :profile="profile"/>
            </div>
          </div>
        </transition>
      </tr>
      <tr>
        <td @click="showSocial = !showSocial">
          <span><i :class="`fas fa-${showSocial ? 'minus' : 'plus'}`"></i></span>
          Redes Sociais
        </td>
        <transition name="slide">
          <div v-show="showSocial">
            <div class="flex my-2 items-center justify-between">
              <social :profile="profile"/>
            </div>
          </div>
        </transition>
      </tr>
      <tr>
        <td @click="showBankData = !showBankData">
          <span><i :class="`fas fa-${showBankData ? 'minus' : 'plus'}`"></i></span>
          Dados Bancários
        </td>
        <transition name="slide">
          <div v-show="showBankData">
            <div class="flex my-2 items-center justify-between">
              <bank-data :profile="profile" :enums="enums" :approvedBankAccount="approvedBankAccount" :status="status"/>
            </div>
          </div>
        </transition>
      </tr>
      <tr>
        <td @click="showDocs = !showDocs">
          <span><i :class="`fas fa-${showDocs ? 'minus' : 'plus'}`"></i></span>
          Upload de Documentos
          <i v-if="!status.todosDocsAprovados" class="fas fa-exclamation-triangle no_docs">
            <span v-if="!status.enviouTodosDocs" class="tooltip">Documentos não enviados</span>
            <span v-else-if="status.docsEmAnalise" class="tooltip">Documentos em análise</span>
            <span v-else class="tooltip">Verifique seus documentos</span>
          </i>
        </td>
        <transition name="slide">
          <div v-show="showDocs">
            <div class="flex my-2 items-center justify-between">
              <docs class="w-full" :docs="docs" @reloadDocs="$emit('reloadDocs')"/>
            </div>
          </div>
        </transition>
      </tr>
      <tr>
        <td @click="showNotifications = !showNotifications">
          <span><i :class="`fas fa-${showNotifications ? 'minus' : 'plus'}`"></i></span>
          Notificações
        </td>
        <transition name="slide">
          <div v-show="showNotifications">
            <div class="flex my-2 items-center justify-between">
              <notifications :profile="profile"/>
            </div>
          </div>
        </transition>
      </tr>
    </table>
  </div>
</template>

<script>
import PersonalData from '@/components/Investor/Profile/Forms/PersonalData'
import Adress from '@/components/Investor/Profile/Forms/Adress'
import Social from '@/components/Investor/Profile/Forms/Social'
import BankData from '@/components/Investor/Profile/Forms/BankData'
import Docs from '@/components/Investor/Profile/Forms/Docs'
import Notifications from '@/components/Investor/Profile/Forms/Notifications'

export default {
  name: 'profile-forms',
  components: { PersonalData, Adress, Social, BankData, Docs, Notifications },
  props: ['profile', 'enums', 'disableCPF', 'docs', 'approvedBankAccount', 'status', 'isBR'],
  inject: ['$validator'],
  data () {
    return {
      showPersonalData: false,
      showAdress: false,
      showSocial: false,
      showBankData: false,
      showDocs: false,
      showNotifications: false
    }
  },
  mounted () {
    const w = window
    const d = w.document
    const e = d.documentElement
    const g = d.getElementsByTagName('body')[0]
    const width = w.innerWidth || e.clientWidth || g.clientWidth
    this.showPersonalData = width >= 1024
  }
}
</script>

<style lang="sass" scoped>
// Tabela
table
  @apply w-full border-none

table tr
  @apply py-1 w-full
  &:focus
    @apply outline-none

table td
  @apply border-b border-primary w-full flex items-center py-1 opacity-75 text-sm text-primary font-semibold cursor-pointer select-none
  @screen lg
    @apply text-base

table td span
  @apply text-sm py-1 mr-3
  @screen lg
    @apply mr-2
  &>i
    @apply text-xs

.partner_name
  @apply font-bold opacity-75
  @screen lg
    @apply w-1/2

.hint
  @apply text-xs text-right
  @screen lg
    @apply w-1/2

.no_docs
  @apply pl-4 text-yellow-500 relative
  &:hover
    &>.tooltip
      @apply visible
  &>.tooltip
    @apply ml-2 absolute invisible bg-yellow-500 text-white text-center text-xss font-semibold rounded-sm whitespace-no-wrap py-1 px-3 z-10 select-none bottom-0
    left: 120%
  &>.tooltip::after
    @apply absolute border-transparent mx-auto z-40
    content: ""
    bottom: 21%
    left: 0
    margin-left: -10px
    border-width: 5px
    border-style: solid
    border-right-color: #ECC94B

</style>
